@media (max-width: 800px) {
  .Header h1 {
    font-size: 40px !important;
  }
  .Header h2 {
    text-align: center !important;
    font-size: 17px;
  }

  .Header img {
    display: none;
  }
}

.Header img {
  max-height: 180px;
}

.Header h1 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 80px;
}

.Header h2 {
  text-align: end;
}

.Header a {
  font-size: 15px;
}
